html, body {
  background-color: rgba(255,255,255,1);
}
.light-footer {
  background: #ffffff;
}

.card {
  /*border: 1px solid rgba(0,0,0,.05);*/
  border-radius: .75rem;
  max-width: 100%;
}

.fill-view {
  height: 100vh !important;
  overflow: hidden !important;
}

.container {
  max-width: 1080px;
}

.download {
  width: 100%;
  max-width: 280px;
}

.icon {
  width: 100%;
  max-width: 120px;
  -webkit-filter: drop-shadow(0px 5px 8px #00000022);
  filter: drop-shadow(0px 5px 8px #00000022);
}

/*
 * Custom translucent site header
 */

.site-header {
  background-color: rgba(0, 0, 0, .85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.site-header a {
  color: #999;
  transition: ease-in-out color .15s;
}
.site-header a:hover {
  color: #fff;
  text-decoration: none;
}

/*
 * Dummy devices (replace them with your own or something else entirely!)
 */

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}


/*
 * Extra utilities
 */


 .container {
  padding-right: 0px;
  padding-left: 0px;
 }

 .roundy {
   border-radius: 0px;
   background-color: #ffffff;
   z-index: 99;
 }

.flex-equal > * {
  -ms-flex: 1;
  flex: 1;
}

.card {
  border: 2px solid rgba(0,0,0,.05);
  border-radius: .75rem;
  max-width: 100%;
}

@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    flex: 1;
  }

  .roundy {
    border-radius: 21px;
    border: 2px solid rgba(0,0,0,.05);
    background-color: #ffffff;
    z-index: 99;
  }

  .hideUnder {
    margin-top: -100px;
  }

  .hideBuffer {
    padding-bottom: 50px;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
   }

}

.overflow-hidden { overflow: hidden; }
